@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply antialiased;
  background-color: #f6f8fe;
}

.btn {
  @apply rounded-full leading-tight font-medium text-white bg-red-500 hover:bg-red-500/75;
}
.btn-disabled {
  @apply rounded-full leading-tight font-medium text-white bg-red-500/60 pointer-events-none;
}
.btn-secondary {
  @apply rounded-full leading-tight font-medium bg-white hover:bg-gray-100 transition-all duration-200 ease-in-out border border-gray-border;
}
.btn-secondary-disabled {
  @apply rounded-full leading-tight font-medium bg-gray-200 opacity-50 pointer-events-none;
}
.btn-outline {
  @apply rounded-full leading-tight font-medium border-red-500 border hover:bg-blue-600 hover:text-off-black transition-all duration-200 ease-in-out cursor-pointer;
}
.btn-outline-disabled {
  @apply rounded-full leading-tight font-medium border-red-500/50 border text-off-white/30;
}
